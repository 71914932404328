<template>
  <div class="son-page">
    <div class="content_box">
      <Form style="margin-top:30px" label-position="left" :label-width="90">
          <FormItem label="会员须知：">
            <div id="websiteEditorElem" style="background: #ffffff;"></div>
          </FormItem>
      </Form>
      <div class="bottom_box"><Button @click="baocun" class="btn">保存</Button></div>
    </div>
  </div>
</template>

<script>
import {noticeInfo,notice} from '@/api/user'
import E from 'wangeditor'
import config from '@/config';
import {getCookies} from '@/utils/helper'
export default {
  name: "canteen",
  data(){
    return{
      uploads: config.apiUrl + '/storeapi/data/uploads',
      phoneEditor:'',
    }
  },
  mounted() {
    this.phoneEditor = new E('#websiteEditorElem')
    this.phoneEditor.config.uploadImgServer = this.uploads// 请求地址
    // this.phoneEditor.config.showLinkImg = false // 隐藏网络图片
    this.phoneEditor.config.uploadFileName = 'file'  //上传参数 自定义
    this.phoneEditor.config.uploadImgHeaders = {
      
          'author-token':getCookies('canyin_token') // 设置请求头
    }
    this.phoneEditor.config.uploadImgHooks = {  //监听
        customInsert: function (insertImg, result) {
            insertImg(result.data.path)
        }
    }
    this.phoneEditor.create() // 创建一个富文本编辑器
  },
  created() {
    this.getnoticeInfo()
  },
  methods:{
    getnoticeInfo(){
      noticeInfo().then(res=>{
        this.phoneEditor.txt.html(res.data.desc)
      })
    },
    baocun(){
      notice({desc:this.phoneEditor.txt.html()}).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
  }
}
</script>

<style scoped>
.son-page {

}
.content_box{
  position: relative;
  width: 100%;
  background: #FFF;
  height: 88vh;
  padding: 20px 30px;
  border-radius: 12px;
}
.bottom_box{
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.textar{
  width: 700px;
  max-width: 700px;
  min-width: 700px;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  outline: none;
  border: 1px solid #D3D3D3;
}
</style>
