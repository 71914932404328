<template>
    <div class="page-style">
        <div class="left-menu">
            <div v-for="(item, index) in leftMenu" :key="index">
                <div v-if="item.children && item.children.length != 0">
                    <div
                        class="menu-nav"
                        :class="item.show ? 'active1' : ''"
                        @click="changeLabel(item)"
                    >
                        <Icon
                            :type="item.icon"
                            size="24"
                            style="margin-right: 10px"
                        />
                        {{ item.title }}
                        <Icon v-if="item.show" type="ios-arrow-down" />
                        <Icon v-else type="ios-arrow-forward" />
                    </div>
                    <div v-if="item.show">
                        <div v-for="(j, k) in item.children" :key="k">
                            <div
                                class="menu-nav"
                                :class="chilIndex === j.src ? 'active' : ''"
                                @click="chilLabel(j)"
                            >
                                <!-- <Icon :type="j.icon" size="24" style="margin-right: 10px"/> -->
                                <span class="hezi"></span>
                                {{ j.title }}
                                <Icon
                                    v-if="j.children && j.children.length != 0"
                                    type="ios-arrow-down"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <!-- 当data_id==676、903时，才有定时计划 -->
                    <div
                        class="menu-nav"
                        :class="activeIndex === item.src ? 'active' : ''"
                        @click="changeLabel(item)"
                        v-if="
                            (item.id == 63 &&
                                $store.state.userInfo.data_id == 676) ||
                            (item.id == 63 &&
                                $store.state.userInfo.data_id == 903) ||
                            item.id != 63
                        "
                    >
                        <Icon
                            :type="item.icon"
                            size="24"
                            style="margin-right: 10px"
                        />
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content">
            <div class="content-del">
                <div v-if="activeIndex === '/admin/user/overview'">
                    <Canteen></Canteen>
                </div>
                <div v-if="activeIndex === '/admin/user/userlist'">
                    <Takeout></Takeout>
                </div>
                <div v-if="activeIndex === '/admin/user/usersettings'">
                    <Usersettings></Usersettings>
                </div>
                <div v-if="activeIndex === '/admin/user/examine'">
                    <Examine></Examine>
                </div>
                <div v-if="activeIndex === '/admin/user/timedschedule'">
                    <Timedschedule></Timedschedule>
                </div>
                <div v-if="chilIndex === '/admin/user/level'">
                    <Membershiplevel></Membershiplevel>
                </div>
                <div v-if="chilIndex === '/admin/user/growthvaluerule'">
                    <Growthvalue></Growthvalue>
                </div>
                <div v-if="chilIndex === '/admin/user/cardopening'">
                    <Bankcard></Bankcard>
                </div>
                <div v-if="chilIndex === '/admin/user/notes'">
                    <Notice></Notice>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCookies } from "@/utils/helper";
import Canteen from "./components/canteen";
import Examine from "./components/examine";
import Timedschedule from "./components/timedschedule";
import Takeout from "./components/takeout";
import Usersettings from "./components/usersettings";
import Membershiplevel from "./components/Membershiplevel";
import Growthvalue from "./components/growthvalue";
import Bankcard from "./components/bankcard";
import Notice from "./components/notice";
export default {
    components: {
        Takeout,
        Canteen,
        Usersettings,
        Membershiplevel,
        Growthvalue,
        Bankcard,
        Notice,
        Examine,
        Timedschedule,
    },
    data() {
        return {
            decide: 2,
            leftMenu: [
                {
                    icon: "ios-cafe-outline",
                    label: "总览",
                    path: "/admin/user/overview",
                },
                {
                    icon: "ios-list",
                    label: "用户列表",
                    path: "/admin/user/userlist",
                },
                {
                    icon: "ios-list",
                    label: "用户审核",
                    path: "/admin/user/examine",
                },
                {
                    icon: "ios-list",
                    label: "定时计划",
                    path: "/admin/user/timedschedule",
                },
                {
                    icon: "ios-cog-outline",
                    label: "用户设置",
                    path: "/admin/user/usersettings",
                },
                {
                    icon: "ios-contact-outline",
                    label: "会员设置",
                    path: "/admin/user/member",
                    show: false,
                    children: [
                        {
                            icon: "ios-bicycle",
                            label: "会员等级",
                            path: "/admin/user/level",
                        },
                        {
                            icon: "ios-bicycle",
                            label: "成长值规则",
                            path: "/admin/user/growthvaluerule",
                        },
                        {
                            icon: "ios-bicycle",
                            label: "开卡设置",
                            path: "/admin/user/cardopening",
                        },
                        {
                            icon: "ios-bicycle",
                            label: "会员须知",
                            path: "/admin/user/notes",
                        },
                    ],
                },
            ],
            activeIndex: 0,
            chilIndex: null,
        };
    },
    mounted() {},
    created() {
        let menu = this.$store.state.menu;
        console.log(menu);
        menu.forEach((item) => {
            if (item.id == 15) {
                item.children.forEach((j) => {
                    if (j.src == "/admin/user/member") {
                        j.show = false;
                    }
                });
                if (this.$store.state.activeIndex == 0) {
                    this.activeIndex =
                        item.children[this.$store.state.activeIndex].src;
                } else {
                    this.activeIndex = this.$store.state.activeIndex;
                }
                this.leftMenu = item.children;
            }
        });
    },
    methods: {
        changeLabel(item) {
            if (item.children && item.children.length != 0) {
                item.show = !item.show;
                this.activeIndex = null;
                if (this.chilIndex == null) {
                    this.chilIndex = item.children[0].src;
                }
            } else {
                this.leftMenu.forEach((item) => {
                    if (item.show) {
                        item.show = false;
                    }
                });
                this.$store.commit("setActiveIndex", item.src);
                this.chilIndex = null;
                this.activeIndex = item.src;
            }
        },
        chilLabel(item) {
            this.activeIndex = null;
            this.chilIndex = item.src;
        },
    },
};
</script>
<style scoped>
.page-style {
    display: flex;
}

.left-menu {
    width: 210px;
    height: 93vh;
    background: white;
    margin-right: 20px;
    padding: 20px 20px;
    box-sizing: border-box;
}

.right-content {
    /* flex: 1; */
    width: calc(100% - 230px);
    padding: 20px 20px 0 0;
    box-sizing: border-box;
}
.content-del {
    /*background: #fff;*/
    width: 100%;
    height: 88vh;
    border-radius: 8px;
    overflow: auto;
}
.menu-nav {
    border: 1px solid #fff;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    padding-left: 20px;
    border-radius: 200px;
    box-sizing: border-box;
    margin-bottom: 16px;
}
.menu-nav:hover {
    background: #fff7f2;
    color: #686868;
}
.menu-nav.active {
    /* background: #FF761D; */
    border: 1px solid #ff761d;
    color: #ff761d;
    /* color: white; */
}
.active1 {
    color: #ff761d;
}
.hezi {
    display: block;
    width: 24px;
    height: 1px;
    margin-left: 10px;
}
</style>
