<template>
    <div class="son-page">
        <div class="top-change">
            <div class="top-search">
                <Form inline :label-width="100">
                    <FormItem style="margin: 0" label="编号：">
                        <Input
                            placeholder="请输入编号"
                            v-model="storePrams.users_number"
                            style="width: 180px; margin-right: 20px"
                        />
                    </FormItem>
                    <FormItem style="margin: 0" label="昵称/手机号：">
                        <Input
                            placeholder="请输入昵称/手机号"
                            v-model="storePrams.keyword"
                            style="width: 180px; margin-right: 20px"
                        />
                    </FormItem>
                    <FormItem style="margin: 0" label="用户类型：">
                        <Select
                            v-model="storePrams.status"
                            style="width: 180px"
                        >
                            <Option
                                v-for="item in cityList"
                                :value="item.value"
                                :key="item.value"
                                >{{ item.label }}</Option
                            >
                        </Select>
                    </FormItem>
                    <FormItem style="margin: 0" label="注册日期：">
                        <DatePicker
                            @on-change="time"
                            type="daterange"
                            placement="bottom-end"
                            placeholder="点击选择日期"
                            style="width: 200px"
                        ></DatePicker>
                    </FormItem>
                    <FormItem style="margin: 0">
                        <div style="display: flex">
                            <Button @click="searchBtn" class="btn">搜索</Button>
                            <Button @click="daochu" style="margin-left: 20px"
                                >导出</Button
                            >
                        </div>
                    </FormItem>
                </Form>
            </div>
        </div>
        <div class="table-wrap">
            <Button @click="createMembers('add')" class="btn">创建会员</Button>
            <Table
                style="margin-top: 20px"
                ref="selection"
                stripe
                :columns="worlkColumns"
                :data="data6"
            >
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
                <template slot-scope="{ row }" slot="user_number">
                    <div style="cursor: pointer" @click="showQrcode(row)">
                        {{ row.user_number }}
                    </div>
                </template>
                <template slot-scope="{ row }" slot="sex">
                    <div v-if="row.wx_sex == 1">男</div>
                    <div v-else-if="row.wx_sex == 2">女</div>
                    <div v-else>未知</div>
                </template>
                <template slot-scope="{ row }" slot="level_name">
                    <Button @click="editlevel_Modal(row)">{{
                        row.level_name ? row.level_name : "暂无等级"
                    }}</Button>
                </template>
                <template slot-scope="{ row }" slot="money">
                    <Button @click="balanceBtn(row.id)">{{ row.money }}</Button>
                </template>
                <template slot-scope="{ row }" slot="order_num">
                    <Button @click="orderrecord(row.id)">{{
                        row.order_num
                    }}</Button>
                </template>
                <template slot-scope="{ row }" slot="growthvalue">
                    <Button @click="growthvalueBtn(row.id)">{{
                        row.val
                    }}</Button>
                </template>
                <template slot-scope="{ row }" slot="integral">
                    <Button @click="integralBtn(row.id)">{{
                        row.integral
                    }}</Button>
                </template>
                <template slot-scope="{ row }" slot="source">
                    <div style="display: flex; justify-content: center">
                        <div
                            style="margin-right: 5px"
                            v-if="row.source.indexOf('1') != -1"
                        >
                            <img src="../../../assets/wx.png" alt="" />
                        </div>
                        <div v-if="row.source.indexOf('2') != -1">
                            <img src="../../../assets/zfb.png" alt="" />
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="it_disabled">
                    <i-switch
                        @on-change="switchBtn($event, row.id)"
                        v-model="row.it_disabled"
                        :true-value="1"
                        :false-value="0"
                    />
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <div style="margin: 10px 0 5px">
                        <Button
                            @click="createMembers(row)"
                            type="warning"
                            ghost
                            size="small"
                            >编辑</Button
                        >
                        <Button
                            v-if="row.custom_information"
                            @click="formBtn(row)"
                            style="
                                margin-left: 5px;
                                background: none;
                                border-color: #8c8cf2;
                            "
                            type="primary"
                            ghost
                            size="small"
                            >会员信息</Button
                        >
                    </div>
                    <div
                        style="margin: 5px 0 10px"
                        v-if="!row.wx_id && !row.zfb_id"
                    >
                        <Button
                            @click="delMembers(row)"
                            type="error"
                            ghost
                            size="small"
                            >删除</Button
                        >
                    </div>
                </template>
            </Table>
            <br />
            <Page
                :total="total"
                :current="storePrams.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange"
                :page-size="storePrams.limit"
            />
        </div>
        <Modal
            :mask-closable="false"
            width="800"
            v-model="balancemodal"
            title="用户金额变动记录"
        >
            <Form :label-width="100">
                <FormItem label="当前余额：">
                    <div style="display: flex">
                        <div class="num_Txt">{{ amount }}</div>
                        <div style="margin-left: 20px">
                            <Button @click="changecli">变动</Button>
                        </div>
                    </div>
                </FormItem>
                <FormItem label="日期选择：">
                    <div style="display: flex">
                        <div>
                            <DatePicker
                                @on-change="time1"
                                type="daterange"
                                placement="bottom-start"
                                placeholder="点击选择日期"
                                style="width: 200px"
                            ></DatePicker>
                        </div>
                        <div style="margin-left: 20px">
                            <Button @click="searchBtn1" class="btn"
                                >搜索</Button
                            >
                        </div>
                    </div>
                </FormItem>
            </Form>
            <Form inline :label-width="100">
                <FormItem label="消费总额：">
                    <div class="num_Txt">{{ consumption }}</div>
                </FormItem>
                <FormItem label="充值总额：">
                    <div class="num_Txt">{{ recharge }}</div>
                </FormItem>
            </Form>
            <Table
                ref="selection"
                stripe
                :columns="balanceColumns"
                :data="data5"
            >
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
            </Table>
            <br />
            <Page
                :total="total1"
                :current="balancedata.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange1"
                :page-size="balancedata.limit"
            />
            <div slot="footer">
                <Button @click="balancemodal = false" class="btn">关闭</Button>
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            v-model="changemodal"
            title="余额变动"
            @on-cancel="changecancel"
        >
            <Form :label-width="100">
                <FormItem label="当前余额：">
                    <div>{{ amount }}</div>
                    <div>
                        <RadioGroup v-model="balance.type">
                            <Radio :label="1">增加</Radio>
                            <Radio :label="2">减少</Radio>
                        </RadioGroup>
                    </div>
                </FormItem>
                <FormItem label="余额变动：">
                    <Input
                        v-model="balance.price"
                        placeholder="请输入余额"
                    ></Input>
                </FormItem>
                <FormItem label="变动说明：">
                    <Input
                        v-model="balance.desc"
                        placeholder="请输入变动说明"
                    ></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="changecancel">取消</Button>
                <Button @click="changeadd" class="btn">确认</Button>
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            width="925"
            v-model="ordermodal"
            title="订单记录"
        >
            <Form inline :label-width="100">
                <FormItem label="日期选择：">
                    <DatePicker
                        @on-change="time2"
                        type="daterange"
                        placement="bottom-start"
                        placeholder="点击选择日期"
                        style="width: 200px"
                    ></DatePicker>
                </FormItem>
                <FormItem label="订单ID：">
                    <div style="display: flex">
                        <div>
                            <Input
                                v-model="orderdata.order_no"
                                placeholder="请输入订单ID"
                            ></Input>
                        </div>
                        <div style="margin-left: 20px">
                            <Button @click="searchBtn2" class="btn"
                                >搜索</Button
                            >
                        </div>
                    </div>
                </FormItem>
            </Form>
            <Form inline :label-width="90">
                <FormItem label="总单数：">
                    <div class="num_Txt">{{ order_sum }}</div>
                </FormItem>
                <FormItem label="订单总额：">
                    <div class="num_Txt">{{ order_sum_money }}</div>
                </FormItem>
            </Form>
            <Table ref="selection" stripe :columns="orderColumns" :data="data4">
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
                <template slot-scope="{ row }" slot="business_status">
                    <div v-if="row.business_status == -1">已取消</div>
                    <div v-if="row.business_status == 0">未支付</div>
                    <div v-if="row.business_status == 1">待接单</div>
                    <div v-if="row.business_status == 2">配餐中</div>
                    <div v-if="row.business_status == 3">待配送</div>
                    <div v-if="row.business_status == 4">待结单</div>
                    <div v-if="row.business_status == 5">待取餐</div>
                    <div v-if="row.business_status == 6">已结单</div>
                </template>
                <template slot-scope="{ row }" slot="type">
                    <div v-if="row.type == 1">堂食</div>
                    <div v-if="row.type == 2">外卖</div>
                    <div v-if="row.type == 3">自取</div>
                </template>
                <template slot-scope="{ row }" slot="detail">
                    <Button @click="lookDetail(row)">查看</Button>
                </template>
            </Table>
            <br />
            <Page
                :total="total2"
                :current="orderdata.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange2"
                :page-size="orderdata.limit"
            />
            <div slot="footer">
                <Button @click="ordermodal = false" class="btn">关闭</Button>
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            width="400"
            v-model="Memlevelmodal"
            @on-cancel="levelcancel"
            title="编辑会员等级"
        >
            <Form
                style="height: 180px"
                label-position="left"
                :label-width="120"
            >
                <FormItem style="margin: 10px 0" label="等级修改：">
                    <Select v-model="leveData.level_id" style="width: 200px">
                        <Option
                            v-for="item in leveList"
                            :value="item.id"
                            :key="item.id"
                            >{{ item.level_name }}</Option
                        >
                    </Select>
                </FormItem>
                <FormItem style="margin: 10px 0" label="赠送升级礼包：">
                    <RadioGroup v-model="leveData.is_handsel">
                        <Radio :label="0">否</Radio>
                        <Radio :label="1">是</Radio>
                    </RadioGroup>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="levelcancel">取消</Button>
                <Button @click="levelconfig" class="btn">确定</Button>
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            width="500"
            v-model="membermodal"
            @on-cancel="membercancel"
            :title="memberTitle"
        >
            <div class="member_style">
                <Form label-position="left" :label-width="140">
                    <FormItem label="姓名：">
                        <Input
                            v-model="memberData.name"
                            placeholder="请输入姓名"
                            style="width: 260px"
                        ></Input>
                    </FormItem>
                    <FormItem label="手机号：">
                        <Input
                            v-model="memberData.phone"
                            placeholder="请输入手机号"
                            style="width: 260px"
                        ></Input>
                    </FormItem>
                    <FormItem label="生日：">
                        <DatePicker
                            @on-change="time3"
                            v-model="memberData.birthday"
                            type="date"
                            placeholder="点击选择生日"
                            style="width: 260px"
                        ></DatePicker>
                    </FormItem>
                    <span v-if="!memberData.id">
                        <FormItem style="margin: 10px 0" label="会员等级：">
                            <Select
                                v-model="memberData.level_id"
                                style="width: 260px"
                            >
                                <Option
                                    v-for="item in leveList"
                                    :value="item.id"
                                    :key="item.id"
                                    >{{ item.level_name }}</Option
                                >
                            </Select>
                        </FormItem>
                        <FormItem
                            v-if="memberData.level_id"
                            label="是否赠送升级礼包："
                        >
                            <RadioGroup v-model="memberData.is_handsel">
                                <Radio label="0">不赠送</Radio>
                                <Radio style="margin-left: 40px" label="1"
                                    >赠送</Radio
                                >
                            </RadioGroup>
                        </FormItem>
                    </span>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="membercancel">取消</Button>
                <Button @click="memberconfig" class="btn">确定</Button>
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            width="500"
            v-model="formModal"
            @on-cancel="formcancel"
            title="表单查看"
        >
            <div>
                <Form
                    v-for="(item, index) of custom_information"
                    :key="index"
                    label-position="left"
                    :label-width="100"
                >
                    <FormItem v-if="item.type == 1" :label="item.title + '：'">
                        <Input v-model="item.default_value"></Input>
                    </FormItem>
                    <FormItem v-if="item.type == 2" :label="item.title + '：'">
                        <textarea
                            class="textarea_style"
                            v-model="item.default_value"
                            cols="30"
                            rows="10"
                        ></textarea>
                    </FormItem>
                    <FormItem v-if="item.type == 3" :label="item.title + '：'">
                        <RadioGroup
                            v-model="item.option_title[item.default_value]"
                        >
                            <Radio
                                v-for="(j, k) of item.option_title"
                                :key="k + 'r'"
                                :label="j"
                            ></Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem v-if="item.type == 4" :label="item.title + '：'">
                        <CheckboxGroup v-model="item.default_value">
                            <Checkbox
                                v-for="(o, p) of item.option_title"
                                :key="p + 'c'"
                                :label="p"
                                >{{ o }}</Checkbox
                            >
                        </CheckboxGroup>
                    </FormItem>
                    <FormItem v-if="item.type == 5" :label="item.title + '：'">
                        <Select v-model="item.default_value">
                            <Option
                                v-for="(s, sind) in item.option_title"
                                :value="sind"
                                :key="sind"
                                >{{ s }}</Option
                            >
                        </Select>
                    </FormItem>
                    <FormItem v-if="item.type == 6" :label="item.title + '：'">
                        <DatePicker
                            style="width: 368px"
                            v-model="item.default_value"
                            type="date"
                        ></DatePicker>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="formModal = false">取消</Button>
                <Button @click="formModalBtn" class="btn">确定</Button>
            </div>
        </Modal>
        <!-- 积分 -->
        <Modal
            :mask-closable="false"
            width="925"
            v-model="integralModal"
            title="积分"
        >
            <Form inline :label-width="100">
                <FormItem label="日期选择：">
                    <DatePicker
                        @on-change="time4"
                        type="daterange"
                        placement="bottom-start"
                        placeholder="点击选择日期"
                        style="width: 200px"
                    ></DatePicker>
                    <Button
                        style="margin-left: 20px"
                        @click="searchBtn3"
                        class="btn"
                        >搜索</Button
                    >
                </FormItem>
            </Form>
            <Table
                ref="selection"
                stripe
                :columns="integralColumns"
                :data="data7"
            >
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
            </Table>
            <br />
            <Page
                :total="total3"
                :current="integraldata.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange3"
                :page-size="integraldata.limit"
            />
            <div slot="footer">
                <Button @click="integralModal = false" class="btn">关闭</Button>
            </div>
        </Modal>
        <!-- 成长值 -->
        <Modal
            :mask-closable="false"
            width="925"
            v-model="growthvalueModal"
            title="成长值"
        >
            <Form inline :label-width="100">
                <FormItem label="日期选择：">
                    <DatePicker
                        @on-change="time5"
                        type="daterange"
                        placement="bottom-start"
                        placeholder="点击选择日期"
                        style="width: 200px"
                    ></DatePicker>
                    <Button
                        style="margin-left: 20px"
                        @click="searchBtn4"
                        class="btn"
                        >搜索</Button
                    >
                </FormItem>
            </Form>
            <Table
                ref="selection"
                stripe
                :columns="growthvalueColumns"
                :data="data8"
            >
                <template slot-scope="{ index }" slot="index">
                    <div>{{ index + 1 }}</div>
                </template>
            </Table>
            <br />
            <Page
                :total="total4"
                :current="growthvaluedata.page"
                style="text-align: right"
                show-elevator
                show-total
                @on-change="pageChange4"
                :page-size="growthvaluedata.limit"
            />
            <div slot="footer">
                <Button @click="growthvalueModal = false" class="btn"
                    >关闭</Button
                >
            </div>
        </Modal>
        <Modal
            :mask-closable="false"
            width="450"
            v-model="show_qr"
            title="二维码"
            style="text-align: center"
        >
            <!-- <vueQr v-if="$store.state.pay_url.wx_app_id" :text="wx_qr_text"></vueQr>
        <vueQr v-if="$store.state.pay_url.zfb_app_id" :text="ali_qr_text"></vueQr> -->
            <div
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                "
            >
                <div v-if="$store.state.pay_url.wx_app_id">
                    <vueQr :text="wx_qr_text"></vueQr>
                    <!-- <div>微信二维码</div> -->
                </div>
            </div>

            <div>扫描此二维码，登录时微信将会绑定该会员信息</div>
            <div>一旦绑定，则用户原会员信息将会被删除</div>
            <div slot="footer"></div>
        </Modal>
        <!-- <div v-if="orderdetail" class="out-pop">
            <div class="pop"></div>
            <div class="pop-card">
                <div v-if="detailData.business_status == -1" class="status-pic">
                    <img src="../../../assets/status/1.png" />
                </div>
                <div v-if="detailData.business_status == 6" class="status-pic">
                    <img src="../../../assets/status/2.png" />
                </div>
                <div @click="orderdetail = false" class="close">
                    <Icon type="md-close" size="30" />
                </div>
                <div v-if="detailData.type == 1" class="title">食堂订单</div>
                <div v-if="detailData.type == 2" class="title">外卖订单</div>
                <div v-if="detailData.type == 3" class="title">自取订单</div>
                <div
                    style="display: flex; justify-content: space-between"
                    class="content"
                >
                    <div>编号：{{ detailData.number }}</div>
                    <div>订单号：{{ detailData.order_no }}</div>
                </div>
                <div v-if="detailData.desk_number" class="content">
                    桌号 : {{ detailData.desk_number }}号桌
                </div>
                <div class="content">
                    就餐时间 : {{ detailData.created_at }}
                </div>
                <div class="content">订单金额 : {{ detailData.money }}</div>
                <div class="content">用户昵称 : {{ detailData.name }}</div>
                <div class="content">
                    用户编号 : {{ detailData.member_number }}
                </div>
                <div v-if="detailData.w_name" class="content">
                    姓名 : {{ detailData.w_name }}
                </div>
                <div v-if="detailData.w_phone" class="content">
                    联系方式 : {{ detailData.w_phone }}
                </div>
                <div v-if="detailData.w_address" class="content">
                    地址 : {{ detailData.w_address }}
                </div>
                <div class="content shops">
                    <div
                        class="item-shops"
                        v-for="(item, index) of detailData.goods_data"
                        :key="index"
                    >
                        {{ item.goods_name }} X {{ item.num }}
                    </div>
                </div>
                <div class="content pop-btn">
                    <Button @click="orderdetail = false">关闭</Button>
                </div>
            </div>
        </div> -->
        <OrderInfo :order_id.sync="order_id"></OrderInfo>
    </div>
</template>

<script>
import {
    userList,
    userBlock,
    moneyIndex,
    balanceChange,
    orderIndex,
    orderInfo,
    listLevel,
    updateLevel,
    addUser,
    updateUser,
    get_form_info,
    edit_form_info,
    getUserValList,
    getUserIntegralList,
    delUser,
} from "@/api/user";
import config from "@/config";
import Moment from "moment";
import { getCookies } from "@/utils/helper";
import vueQr from "vue-qr";
import OrderInfo from "@/components/order/info.vue";
export default {
    name: "canteen",
    components: {
        vueQr,
        OrderInfo,
    },
    data() {
        return {
            order_id: 0,
            wx_qr_text: "",
            ali_qr_text: "",
            show_qr: false,
            uploads: config.apiUrl + "/storeapi/memberExport",
            balancemodal: false,
            changemodal: false,
            integralModal: false,
            growthvalueModal: false,
            ordermodal: false,
            Memlevelmodal: false,
            membermodal: false,
            formModal: false,
            memberData: {
                name: "",
                phone: "",
                birthday: "",
                level_id: 0,
                is_handsel: "0",
            },
            custom_infoData: {
                id: 0,
                custom_information: [],
            },
            memberTitle: "",
            orderdetail: false,
            amount: null,
            recharge: null,
            consumption: null,
            order_sum: null,
            order_sum_money: null,
            switch1: 1,
            detailData: {},
            balance: {
                user_id: null,
                price: "",
                type: 1,
                desc: "",
            },
            cityList: [
                {
                    value: "0",
                    label: "所有用户",
                },
                {
                    value: "1",
                    label: "会员用户",
                },
                {
                    value: "2",
                    label: "普通用户",
                },
                {
                    value: "3",
                    label: "已拉黑",
                },
            ],
            leveList: [],
            leveData: {
                user_id: "",
                level_id: "",
                is_handsel: 0,
            },
            data6: [],
            data5: [],
            data4: [],
            data7: [],
            data8: [],
            worlkColumns: [
                {
                    title: "#",
                    slot: "index",
                    align: "center",
                    width: 60,
                },
                {
                    title: "用户编号",
                    slot: "user_number",
                    align: "center",
                    width: 110,
                },
                {
                    title: "会员编号",
                    key: "member_number",
                    align: "center",
                    width: 230,
                },
                {
                    title: "姓名",
                    key: "name",
                    align: "center",
                    width: 110,
                },
                {
                    title: "昵称",
                    key: "wx_name",
                    align: "center",
                    width: 110,
                },
                {
                    title: "性别",
                    slot: "sex",
                    align: "center",
                    width: 80,
                },
                {
                    title: "会员等级",
                    slot: "level_name",
                    align: "center",
                    width: 160,
                },
                {
                    title: "手机号",
                    key: "phone",
                    align: "center",
                    width: 150,
                },
                {
                    title: "余额",
                    slot: "money",
                    align: "center",
                    width: 150,
                },
                {
                    title: "订单",
                    slot: "order_num",
                    align: "center",
                    width: 150,
                },
                {
                    title: "成长值",
                    slot: "growthvalue",
                    align: "center",
                    width: 150,
                },
                {
                    title: "积分",
                    slot: "integral",
                    align: "center",
                    width: 150,
                },
                {
                    title: "来源",
                    slot: "source",
                    align: "center",
                    width: 150,
                },
                {
                    title: "注册时间",
                    key: "created_at",
                    align: "center",
                    width: 180,
                },
                {
                    title: "拉黑",
                    slot: "it_disabled",
                    align: "center",
                    width: 160,
                },
                {
                    title: "操作",
                    slot: "operation",
                    align: "center",
                    width: 160,
                    fixed: "right",
                },
            ],
            balanceColumns: [
                {
                    title: "#",
                    slot: "index",
                    align: "center",
                    width: 60,
                },
                {
                    title: "时间",
                    key: "created_at",
                    align: "center",
                },
                {
                    title: "金额",
                    key: "money",
                    align: "center",
                },
                {
                    title: "类型",
                    key: "scene",
                    align: "center",
                },
                {
                    title: "说明",
                    key: "desc",
                    align: "center",
                },
            ],
            orderColumns: [
                {
                    title: "#",
                    slot: "index",
                    align: "center",
                    width: 60,
                },
                {
                    title: "单号",
                    key: "number",
                    align: "center",
                    width: 100,
                },
                {
                    title: "订单编号",
                    key: "number_no",
                    align: "center",
                    width: 140,
                },
                {
                    title: "时间",
                    key: "created_at",
                    width: 180,
                    align: "center",
                },
                {
                    title: "金额",
                    key: "money",
                    width: 110,
                    align: "center",
                },
                {
                    title: "状态",
                    slot: "business_status",
                    width: 100,
                    align: "center",
                },
                {
                    title: "类型",
                    slot: "type",
                    width: 100,
                    align: "center",
                },
                {
                    title: "详情",
                    slot: "detail",
                    width: 100,
                    align: "center",
                },
            ],
            integralColumns: [
                {
                    title: "#",
                    slot: "index",
                    align: "center",
                },
                {
                    title: "积分",
                    key: "num",
                    align: "center",
                },
                {
                    title: "积分描述",
                    key: "desc",
                    align: "center",
                },
                {
                    title: "时间",
                    key: "created_at",
                    align: "center",
                },
            ],
            growthvalueColumns: [
                {
                    title: "#",
                    slot: "index",
                    align: "center",
                },
                {
                    title: "成长值",
                    key: "val",
                    align: "center",
                },
                {
                    title: "成长描述",
                    key: "desc",
                    align: "center",
                },
                {
                    title: "时间",
                    key: "created_at",
                    align: "center",
                },
            ],
            total: 0,
            storePrams: {
                keyword: "",
                users_number: "",
                status: "0",
                start_time: "",
                end_time: "",
                page: 1,
                limit: 10,
            },
            total1: 0,
            balancedata: {
                user_id: null,
                page: 1,
                limit: 5,
                start_time: "",
                end_time: "",
            },
            total2: 0,
            orderdata: {
                user_id: null,
                order_no: "",
                page: 1,
                limit: 5,
                start_time: "",
                end_time: "",
            },
            custom_information: [],
            total3: 0,
            integraldata: {
                user_id: null,
                page: 1,
                limit: 5,
                start_time: "",
                end_time: "",
            },
            total4: 0,
            growthvaluedata: {
                user_id: null,
                page: 1,
                limit: 5,
                start_time: "",
                end_time: "",
            },
        };
    },
    created() {
        this.getList();
        this.listLevel();
    },
    methods: {
        showQrcode(e) {
            console.log(e);
            if (!e.phone) {
                this.$Message.error("请先填写手机号");
                return;
            }
            this.show_qr = true;
            this.wx_qr_text = `${this.$store.state.pay_url.url.coverapi_url}${this.$store.state.pay_url.wx_app_id}?is_cover=1&cover_phone=${e.phone}&data_id=${e.data_id}`;
            this.ali_qr_text = `${this.$store.state.pay_url.url.coverapi_url}${this.$store.state.pay_url.zfb_app_id}?is_cover=1&cover_phone=${e.phone}&data_id=${e.data_id}`;
            // console.log('wx_qr_text', this.wx_qr_text)
            // console.log('ali_qr_text', this.ali_qr_text)
        },
        getList() {
            userList(this.storePrams).then((res) => {
                this.total = res.data.total;
                this.data6 = res.data.data;
            });
        },
        listLevel() {
            listLevel().then((res) => {
                this.leveList = res.data;
            });
        },
        getmoneyIndex() {
            moneyIndex(this.balancedata).then((res) => {
                this.amount = res.data.amount;
                this.recharge = res.data.recharge;
                this.consumption = res.data.consumption;
                this.total1 = res.data.total;
                this.data5 = res.data.data;
            });
        },
        getorderIndex() {
            orderIndex(this.orderdata).then((res) => {
                this.order_sum = res.data.order_sum;
                this.order_sum_money = res.data.order_sum_money;
                this.total2 = res.data.total;
                this.data4 = res.data.data;
            });
        },
        formModalBtn() {
            this.custom_information.forEach((item) => {
                if (item.type == 6) {
                    item.default_value = Moment(item.default_value).format(
                        "YYYY-MM-DD"
                    );
                }
            });
            this.custom_infoData.custom_information = this.custom_information;
            edit_form_info(this.custom_infoData)
                .then((res) => {
                    this.getList();
                    this.formModal = false;
                    this.$Message.success(res.msg);
                })
                .catch((err) => {
                    this.$Message.error(err.msg);
                });
        },
        formBtn(e) {
            this.custom_infoData.id = e.id;
            get_form_info({ id: e.id })
                .then((res) => {
                    this.custom_information = res.data.custom_information;
                    this.custom_information.forEach((item) => {
                        if (item.type == 5) {
                            item.default_value = parseInt(item.default_value);
                        }
                    });
                    this.formModal = true;
                })
                .catch((err) => {
                    this.$Message.error(err.msg);
                });
        },
        formcancel() {},
        formconfig() {},
        switchBtn(e, id) {
            userBlock({ id: id, it_disabled: e }).then((res) => {
                this.$Message.success(res.msg);
            });
        },
        // 积分弹框
        integralBtn(id) {
            this.integraldata.user_id = id;
            this.integralModal = true;
            this.getUserIntegralList();
        },
        // 成长值弹框
        growthvalueBtn(id) {
            this.growthvaluedata.user_id = id;
            this.growthvalueModal = true;
            this.getUserValList();
        },
        daochu() {
            location.href =
                this.uploads +
                "?token=" +
                getCookies("canyin_token") +
                "&start_time=" +
                this.storePrams.start_time +
                "&end_time=" +
                this.storePrams.end_time;
        },
        time(e) {
            this.storePrams.start_time = e[0];
            this.storePrams.end_time = e[1];
        },
        time1(e) {
            this.balancedata.start_time = e[0];
            this.balancedata.end_time = e[1];
        },
        time2(e) {
            this.orderdata.start_time = e[0];
            this.orderdata.end_time = e[1];
        },
        time3(e) {
            this.memberData.birthday = e;
        },
        time4(e) {
            this.integraldata.start_time = e[0];
            this.integraldata.end_time = e[1];
        },
        time5(e) {
            this.growthvaluedata.start_time = e[0];
            this.growthvaluedata.end_time = e[1];
        },
        searchBtn() {
            this.storePrams.page = 1;
            this.getList();
        },
        searchBtn1() {
            this.balancedata.page = 1;
            this.getmoneyIndex();
        },
        searchBtn2() {
            this.getorderIndex();
        },
        searchBtn3() {
            this.integraldata.page = 1;
            this.getUserIntegralList();
        },
        searchBtn4() {
            this.growthvaluedata.page = 1;
            this.getUserValList();
        },
        getUserIntegralList() {
            getUserIntegralList(this.integraldata).then((res) => {
                this.total3 = res.data.total;
                this.data7 = res.data.data;
            });
        },
        getUserValList() {
            getUserValList(this.growthvaluedata).then((res) => {
                this.total4 = res.data.total;
                this.data8 = res.data.data;
            });
        },
        balanceBtn(e) {
            this.balancedata.user_id = e;
            this.getmoneyIndex();
            this.balancemodal = true;
        },
        changecli() {
            this.balance.user_id = this.balancedata.user_id;
            this.changemodal = true;
        },
        changecancel() {
            this.balance.price = "";
            // this.balance.typr = 1
            this.balance.desc = "";
            this.changemodal = false;
        },
        createMembers(e) {
            if (e == "add") {
                this.membermodal = true;
                this.memberTitle = "创建会员";
            } else {
                this.memberData.id = e.id;
                this.memberData.name = e.name;
                this.memberData.phone = e.phone;
                this.memberData.birthday = e.birthday;
                // this.memberData.level_id = e.level_id
                // this.memberData.is_handsel = e.is_handsel
                this.membermodal = true;
                this.memberTitle = "编辑会员";
            }
        },
        delMembers(e) {
            delUser({ id: e.id })
                .then((res) => {
                    this.getList();
                    this.$Message.success(res.msg);
                })
                .catch((err) => {
                    this.$Message.error(err.msg);
                });
        },
        membercancel() {
            this.memberData = {
                name: "",
                phone: "",
                birthday: "",
                level_id: 0,
                is_handsel: "0",
            };
            this.membermodal = false;
        },
        memberconfig() {
            if (this.memberData.id) {
                if (this.memberData.birthday) {
                    this.memberData.birthday = Moment(
                        this.memberData.birthday
                    ).format("YYYY-MM-DD");
                }
                updateUser(this.memberData)
                    .then((res) => {
                        this.$Message.success(res.msg);
                        this.membercancel();
                        this.getList();
                    })
                    .catch((err) => {
                        this.$Message.error(err.msg);
                    });
            } else {
                addUser(this.memberData)
                    .then((res) => {
                        this.$Message.success(res.msg);
                        this.membercancel();
                        this.getList();
                    })
                    .catch((err) => {
                        this.$Message.error(err.msg);
                    });
            }
        },
        changeadd() {
            balanceChange(this.balance)
                .then((res) => {
                    this.getmoneyIndex();
                    this.getList();
                    this.changemodal = false;
                    this.balance.price = "";
                    this.balance.typr = 1;
                    this.balance.desc = "";
                    this.$Message.success(res.msg);
                })
                .catch((err) => {
                    this.$Message.error(err.msg);
                });
        },
        // 点击订单时触发
        orderrecord(e) {
            this.orderdata.user_id = e;
            this.getorderIndex();
            this.ordermodal = true;
        },
        editlevel_Modal(e) {
            // console.log(e.val);
            this.leveData.level_id = e.level_id;
            this.leveData.user_id = e.id;
            this.Memlevelmodal = true;
        },
        levelconfig() {
            if (this.leveData.level_id) {
                this.$Modal.confirm({
                    title: "提示",
                    content: `是否确认修改会员等级！`,
                    onOk: () => {
                        updateLevel(this.leveData)
                            .then((res) => {
                                this.$Message.success(res.msg);
                                this.Memlevelmodal = false;
                                this.getList();
                            })
                            .catch((err) => {
                                this.$Message.error(err.msg);
                            });
                    },
                    onCancel: () => {},
                });
            } else {
                this.$Message.warning("请选择修改等级");
            }
        },
        levelcancel() {
            this.Memlevelmodal = false;
        },
        // 点击订单记录列表（详情）查看
        lookDetail(e) {
            console.log("点击订单记录列表（详情）查看时触发", e);
            this.order_id = e.id;
            this.PaymentData = e;
            //   orderInfo({ order_id: e.id }).then((res) => {
            //     this.detailData = res.data;
            //     this.orderdetail = true;
            //   });
        },
        pageChange(index) {
            this.storePrams.page = index;
            this.getList();
        },
        pageChange1(index) {
            this.balancedata.page = index;
            this.getmoneyIndex();
        },
        pageChange2(index) {
            this.orderdata.page = index;
            this.getorderIndex();
        },
        pageChange3(index) {
            this.integraldata.page = index;
            this.getUserIntegralList();
        },
        pageChange4(index) {
            this.growthvaluedata.page = index;
            this.getUserValList();
        },
    },
};
</script>

<style scoped>
/* .son-page {

} */
.top-change {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 20px 30px;
    box-sizing: border-box;
}
.top-search {
    width: 100%;
    background: white;
    padding: 20px 0;
    border-radius: 10px;
    display: flex;
}
.two-btns {
    display: flex;
    align-items: center;
    margin-left: 40px;
}
.btn-style {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    background: #ff761d;
    width: 80px;
    height: 32px;
}
.btn {
    background: #ff761d;
    color: #fff;
    border-color: #ff761d;
}
.order-item {
    margin-top: 20px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}
.phone {
    font-size: 14px;
    font-weight: bold;
}
.top-num {
    font-size: 13px;
    color: #585858;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.top-num .name1 {
    margin-right: 40px;
}
.bottom-content {
    font-size: 15px;
    margin-top: 20px;
    word-break: break-all;
}
.r-bent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
.member_style {
    display: flex;
    align-items: center;
    justify-content: center;
}
.right1 {
    text-align: right;
}
.l-went {
    flex: 1;
}
.right-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.right-btn .btn1 {
    width: 60px;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff5c11;
    color: #ff5c11;
    margin-left: 20px;
    border-radius: 4px;
    cursor: pointer;
}
.add_search_box {
    display: flex;
    justify-content: space-between;
}
.right-btn .btn2 {
    color: #343434;
    border: 1px solid #555555;
}
.right-btn .btn2:hover,
.right-btn .btn1:hover {
    opacity: 0.6;
}
.table-wrap {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 20px 30px;
    box-sizing: border-box;
    margin-top: 30px;
}
.top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
<style scoped>
.ivu-tabs-nav .ivu-tabs-tab {
    display: inline-block;
    height: 100%;
    padding: 8px 16px;
    margin-right: 16px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    font-size: 15px;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
    color: #ff761d;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
    color: #ff761d;
}
.ivu-tabs-ink-bar {
    height: 2px;
    box-sizing: border-box;
    background-color: #ff761d;
    position: absolute;
    left: 0;
    bottom: 1px;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    transform-origin: 0 0;
}
.ivu-switch-checked {
    border-color: #ff761d;
    background-color: #ff761d;
}
.num_Txt {
    color: #ff761d;
    font-size: 16px;
}
.ivu-radio-checked .ivu-radio-inner {
    border-color: #ff761d;
}
.ivu-radio-inner:after {
    background-color: #ff761d;
}
.ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #ff761d;
    background-color: #ff761d;
}

.pop {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    background: black;
    opacity: 0.3;
}
.pop-card {
    width: 500px;
    border-radius: 6px;
    background: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding: 30px 30px;
    box-sizing: border-box;
}
.pop-card .title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
}
.pop-card .content {
    font-size: 15px;
    color: #343434;
    margin-top: 12px;
}
.pop-card .close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
}
.shops {
    display: flex;
    font-size: 14px !important;
    flex-wrap: wrap;
}
.item-shops {
    width: 20%;
}
.pop-btn {
    display: flex;
    justify-content: flex-end;
}
.status-pic {
    width: 90px;
    height: 61px;
    position: absolute;
    right: 60px;
    top: 45%;
    transform: translateY(-50%);
    transform: rotate(80deg);
}
.status-pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.textarea_style {
    outline: none;
    border-color: #dcdee2;
    width: 100%;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
}
</style>
